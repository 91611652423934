import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { DepositPayee } from '@app/core/enums/deposit.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { ProviderType } from '@app/core/enums/provider-type.enum';
import { RpFpdPreference } from '@app/core/enums/rp-fpd-preference.enum';
import { Frequency } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { PlanCalculationService } from '@app/core/services/plan-calculation.service';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { FpdItemsInternalId } from '@app/shared/components/first-payment-date-proposal/first-payment-date-proposal.component';
import { PlanCalculator } from '@app/shared/interfaces/plan-calculator.interface';
import { BehaviorSubject } from 'rxjs';
import { TreatmentType } from '../../enums/treatment-type.enum';
import { FirstPaymentDateDdItem } from '../../models/common/form-fields.interface';
import { DDRRegex, maxDateValidator, minDateValidator } from '../ddr.validator';
import { FormFieldsService } from '../form-fields/form-fields.service';
import { UserSegmentService } from '../user-segment/user-segment.service';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable()
export class PlusConnectCalculatorService implements PlanCalculator {
  minTreatmentCost = 0;
  minimumDeposit = 0;
  maxDeposit = 0;
  today = new Date();
  tomorrow = this.helperService.addDaysToDate(1);
  private clinicType = JSON.parse(sessionStorage.getItem('user') as string)?.clinic_type;

  treatmentTypeSubject = new BehaviorSubject(TreatmentType.DENTAL); // Dental is default
  private planTypeTypeSubject = new BehaviorSubject(PlanType.Guaranteed); // Plus by default

  MIN_PAYMENT_PLAN_AMOUNT = +this.userSegmentService.getMinPaymentPlanAmount();
  MIN_DEPOSIT_DEN_ORTHO = +this.userSegmentService.getMinDepositDenOrtho();
  private readonly PAYMENT_PLAN_AMOUNT_THRESHOLD = 2000;
  private readonly MAX_TTC_DEN_ORTHO = +this.userSegmentService.getMaxTotalTreatmentCostDenOrtho();

  constructor(
    private helperService: HelperService,
    private planCalculationService: PlanCalculationService,
    private userSettingsService: UserSettingsService,
    private formFieldsService: FormFieldsService,
    private userSegmentService: UserSegmentService
  ) {}

  setPlanType(planType: PlanType): void {
    this.planTypeTypeSubject.next(planType);
  }

  getMaxPaymentPlanAmount(): number {
    return this.isOrtho15kAssured()
      ? +this.userSegmentService.getMaxPaymentPlanAmount15k()
      : +this.userSegmentService.getMaxPaymentPlanAmount();
  }

  getMaxDirectDebitAmount(): number {
    return this.isOrtho15kAssured()
      ? +this.userSegmentService.getMaxDirectDebitAmount15k()
      : +this.userSegmentService.getMaxDirectDebitAmount();
  }

  getDefaultDepositPayee(): string {
    if (!this.userSettingsService.isPayDepositToEnabled()) {
      return DepositPayee.PRACTICE;
    }
    return this.userSettingsService.getDefaultDepositPayee()?.value;
  }

  getDiscountAmount(ttc: number, percentage: number): number {
    const discountedPrice = ttc * (parseFloat(percentage.toString()) / 100);
    return this.helperService.roundUpToTwoDecimal(discountedPrice);
  }

  getMaxTermMonths(formGroup: UntypedFormGroup): number {
    const totalPlanAmount = formGroup.get('paymentPlanTotal')?.value;
    const maxTermMonths =
      totalPlanAmount <= this.PAYMENT_PLAN_AMOUNT_THRESHOLD
        ? this.userSegmentService.getMaxTermMonthsLessThanOr2k()
        : this.userSegmentService.getMaxTermMonthsMoreThan2k();

    if (
      totalPlanAmount <= this.PAYMENT_PLAN_AMOUNT_THRESHOLD &&
      this.helperService.getObjUser()?.clinic_type === ProviderType.ORTHO &&
      this.userSettingsService.isProactiveConnectOnlyProvider()
    ) {
      return +this.userSegmentService.getMaxTermMonthsMoreThan2k();
    }

    return +maxTermMonths;
  }

  getFrequencies(): Frequency[] {
    return this.helperService.getObjFormFields()?.custrecord_mfa_ddr_payment_frequency;
  }

  getMaxTreatmentCost(): number {
    if (this.isDentalClinicAndOrthoTreatment()) {
      return +this.MAX_TTC_DEN_ORTHO;
    }

    return this.isOrtho15kAssured()
      ? +this.userSegmentService.getMaxTotalTreatmentCost15k()
      : +this.userSegmentService.getMaxTotalTreatmentCost();
  }

  getMaximumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    let totalTreatmentCost = formGroup.get('totalTreatmentCost')?.value;

    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      totalTreatmentCost = this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalTreatmentCost, discount));
    }

    const ttcMinusMinPlanAmount = totalTreatmentCost - this.MIN_PAYMENT_PLAN_AMOUNT;

    let maxDeposit = 0;

    // If clinic is dental ortho
    if (this.helperService.getObjUser()?.clinic_type === ProviderType.ORTHO) {
      if (ttcMinusMinPlanAmount <= 0) {
        maxDeposit = 0;
      } else {
        if (totalTreatmentCost === this.MIN_PAYMENT_PLAN_AMOUNT) {
          maxDeposit = totalTreatmentCost * this.getMinimumDeposit(formGroup, discountType);
        } else {
          maxDeposit = ttcMinusMinPlanAmount;
        }
      }
    } else {
      maxDeposit = ttcMinusMinPlanAmount > 0 ? ttcMinusMinPlanAmount : 0;
    }

    return this.helperService.roundUpToTwoDecimal(maxDeposit);
  }

  calculateMinTreatmentCost(): number {
    if (this.isDentalClinicAndOrthoTreatment()) {
      return +this.MIN_PAYMENT_PLAN_AMOUNT + this.MIN_DEPOSIT_DEN_ORTHO;
    }

    return +this.MIN_PAYMENT_PLAN_AMOUNT / (1 - this.getDepositRatio());
  }

  setFieldValidators(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    this.minTreatmentCost = this.calculateMinTreatmentCost();
    this.minimumDeposit = this.getMinimumDeposit(formGroup, discountType);
    this.maxDeposit = this.getMaximumDeposit(formGroup, discountType);

    formGroup
      .get('totalTreatmentCost')
      ?.setValidators([Validators.required, Validators.min(this.minTreatmentCost), Validators.max(this.getMaxTreatmentCost())]);
    formGroup.get('totalTreatmentCost')?.updateValueAndValidity();

    formGroup
      .get('deposit')
      ?.setValidators([
        Validators.required,
        Validators.pattern(DDRRegex.getCurrencyValidation()),
        Validators.min(this.minimumDeposit),
        Validators.max(this.maxDeposit)
      ]);
    formGroup.get('deposit')?.updateValueAndValidity();

    formGroup
      .get('paymentPlanTotal')
      ?.setValidators([
        Validators.required,
        Validators.pattern(DDRRegex.getCurrencyValidation()),
        Validators.min(this.MIN_PAYMENT_PLAN_AMOUNT),
        Validators.max(this.getMaxPaymentPlanAmount())
      ]);
    formGroup.get('paymentPlanTotal')?.updateValueAndValidity();

    formGroup
      .get('quote.0.paymentPlanDurationInMonths')
      ?.setValidators([Validators.min(1), Validators.max(this.getMaxTermMonths(formGroup)), Validators.required]);
    formGroup.get('quote.0.paymentPlanDurationInMonths')?.updateValueAndValidity();

    // discount type determine if module type is proposal
    if (discountType) {
      if (formGroup.get('firstDebitDate.type')?.value === FpdItemsInternalId.Specific) {
        formGroup
          .get('firstDebitDate.specificDate')
          ?.setValidators([Validators.required, minDateValidator(this.tomorrow), maxDateValidator(this.getMaxPaymentStartDate())]);
        formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
      } else {
        formGroup.get('firstDebitDate.relativeOption')?.setValidators(Validators.required);
        formGroup.get('firstDebitDate.relativeInput')?.setValidators(Validators.required);

        const fpdItems = this.formFieldsService.getFpdOptions();
        const selectedFpdItem = fpdItems.find(
          (x) => x.internalid === formGroup.get('firstDebitDate.relativeOption')?.value
        ) as FirstPaymentDateDdItem;

        if (selectedFpdItem) {
          formGroup
            .get('firstDebitDate.relativeInput')
            ?.setValidators([Validators.min(1), Validators.max(+selectedFpdItem.max), Validators.required]);
        }

        formGroup.get('firstDebitDate.relativeOption')?.updateValueAndValidity();
        formGroup.get('firstDebitDate.relativeInput')?.updateValueAndValidity();

        formGroup.get('firstDebitDate.specificDate')?.setValidators(null);
        formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
      }
    } else {
      formGroup
        .get('firstDebitDate.specificDate')
        ?.setValidators([Validators.required, minDateValidator(this.tomorrow), maxDateValidator(this.getMaxPaymentStartDate())]);
      formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
    }
  }

  setTermMonths(formGroup: UntypedFormGroup): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    if (quoteGroup.controls[0].get('monthly')?.value) {
      const durationInMonthBefore = formGroup.get('paymentPlanTotal')?.value / quoteGroup.controls[0].get('monthly')?.value;
      const durationInMonth = Math.ceil(+durationInMonthBefore.toFixed(2));

      // Setting emitEvent false for setTermMonths since it is editable in Plus/Connect plan type
      quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.setValue(durationInMonth, { emitEvent: false });
    }
  }

  getTermMonths(frequency: string): number {
    // return nothing since term months is manual input
    return 0;
  }

  setSinglePayment(formGroup: UntypedFormGroup, frequencies: Frequency[]): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    const quoteName = this.planCalculationService.setDDRSinglePayments(formGroup.get('paymentFrequency')?.value, frequencies);

    if (quoteName) {
      formGroup.get('singlePayments')?.setValue(quoteGroup.controls[0].get(quoteName)?.value);
    }
  }

  setNoOfPayments(formGroup: UntypedFormGroup): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;
    const termMonths = +quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.value;
    formGroup.get('noOfPayments')?.setValue(this.getCalculateNoOfPayments(formGroup.get('paymentFrequency')?.value, termMonths));
  }

  getCalculateNoOfPayments(frequency: string, termMonths: number): number {
    const weeksPerYear = 52;

    switch (frequency) {
      case '1': {
        return Math.floor((termMonths / 12) * weeksPerYear);
      }
      case '2': {
        return Math.floor((termMonths / 12) * (weeksPerYear / 2));
      }
      case '3': {
        return termMonths;
      }
      default: {
        return 0;
      }
    }
  }

  setQuoteGroup(formGroup: UntypedFormGroup, setMonthlyAmount: boolean = true, discountType: DiscountType | null = null): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;
    const termMonths = +quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.value;
    const amountRequiredToPay = this.getPaymentPlanAmount(formGroup, discountType);

    quoteGroup.controls.forEach((quote) => {
      let weeklyCost = null;
      let fortnightlyCost = null;
      let monthlyCost = null;

      if (amountRequiredToPay >= this.MIN_PAYMENT_PLAN_AMOUNT) {
        weeklyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('1', termMonths), amountRequiredToPay);
        fortnightlyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('2', termMonths), amountRequiredToPay);
        monthlyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('3', termMonths), amountRequiredToPay);

        weeklyCost = weeklyCost >= 0 ? weeklyCost : null;
        fortnightlyCost = fortnightlyCost >= 0 ? fortnightlyCost : null;
        monthlyCost = monthlyCost >= 0 ? monthlyCost : null;
      }

      quote.get('weekly')?.setValue(weeklyCost);
      quote.get('fortnightly')?.setValue(fortnightlyCost);
      if (setMonthlyAmount) {
        quote.get('monthly')?.setValue(monthlyCost);
      }
    });
  }

  setPaymentPlanAmount(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    formGroup.get('paymentPlanTotal')?.setValue(this.getPaymentPlanAmount(formGroup, discountType));
  }

  getPaymentPlanAmount(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    const totalPlanValue = formGroup.get('totalTreatmentCost')?.value ? +formGroup.get('totalTreatmentCost')?.value : 0;
    const depositAmount = formGroup.get('deposit')?.value ? +formGroup.get('deposit')?.value : 0;

    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      return this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalPlanValue, discount) - depositAmount);
    }

    return this.helperService.roundUpToTwoDecimal(totalPlanValue - depositAmount);
  }

  setMinimumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    this.minimumDeposit = this.getMinimumDeposit(formGroup, discountType);
    formGroup.get('deposit')?.setValue(this.helperService.roundUpToTwoDecimal(this.getMinimumDeposit(formGroup, discountType)));
  }

  getMinimumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    let totalPlanValue = formGroup.get('totalTreatmentCost')?.value ? +formGroup.get('totalTreatmentCost')?.value : 0;

    // For discount logic
    // TO DO: Move to new
    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      totalPlanValue = this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalPlanValue, discount));
    }

    const depositAmount = formGroup.get('deposit')?.value ? +formGroup.get('deposit')?.value : 0;

    let minDeposit = 0;

    if (this.isDentalClinicAndOrthoTreatment()) {
      if (depositAmount > this.MIN_DEPOSIT_DEN_ORTHO) {
        minDeposit = depositAmount;
      } else {
        minDeposit = this.MIN_DEPOSIT_DEN_ORTHO;
      }
    } else {
      // Check if Ortho Provider & Ortho Treatment
      if (this.isOrthoClinicAndOrthoTreatment() && totalPlanValue > this.getMaxPaymentPlanAmount()) {
        minDeposit = totalPlanValue - this.getMaxPaymentPlanAmount();
      } else {
        minDeposit = totalPlanValue * this.getDepositRatio();
      }
    }

    return this.helperService.roundUpToTwoDecimal(minDeposit);
  }

  getDepositRatio(): number {
    return +this.userSegmentService.getMinDepositRatio();
  }

  /**
    Set to tomorrow
   */
  getDefaultFirstPaymentDate(): Date {
    const fpdPref = this.userSettingsService.getFpdPreference();
    if (fpdPref?.value === RpFpdPreference.FirstNextMonth) {
      const today = new Date();
      const firstNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      return firstNextMonth;
    }

    return this.helperService.addDaysToDate(1);
  }

  getMaxDiscountMsg(selectedDiscountType: DiscountType, maxDiscount: number): string {
    switch (selectedDiscountType) {
      case DiscountType.Currency: {
        return 'Maximum discount must be less than ' + this.helperService.convertToMoney(maxDiscount);
      }
      case DiscountType.Percentage: {
        return `Discount percentage cannot exceed ${Math.floor(maxDiscount)}%`;
      }
    }
  }

  getTotalTreatmentCostWithDiscount(ttc: number, discount: number): number {
    return this.helperService.roundUpToTwoDecimal(ttc - discount);
  }

  getMaxPaymentStartDate(): Date {
    return this.helperService.addDaysToDate(+this.userSegmentService.getMaxStartDateInDays());
  }

  setTreatmentTypeSubj(treatmentType: TreatmentType): void {
    this.treatmentTypeSubject.next(treatmentType);
  }

  getMaxDiscount(discountType: DiscountType, totalTreatmentCost: number, deposit: number): number {
    switch (discountType) {
      case DiscountType.Currency: {
        // return totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return +totalTreatmentCost;
      }
      case DiscountType.Percentage: {
        // const maxAmount = totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return 100;
      }
    }
  }

  private getAmountPerFrequency(numberOfPayments: number, paymentPlanAmount: number): number {
    const amountPerUnit = paymentPlanAmount / numberOfPayments;

    return numberOfPayments !== 0 ? this.helperService.roundUpToTwoDecimal(amountPerUnit ? Math.ceil(amountPerUnit * 20) / 20 : 0) : 0;
  }

  private isOrthoClinicAndOrthoTreatment(): boolean {
    return this.clinicType === ProviderType.ORTHO && this.treatmentTypeSubject.getValue() === TreatmentType.ORTHO;
  }

  private isDentalClinicAndOrthoTreatment(): boolean {
    return this.clinicType === ProviderType.DENTAL && this.treatmentTypeSubject.getValue() === TreatmentType.ORTHO;
  }

  private isOrtho15kAssured(): boolean {
    switch (this.planTypeTypeSubject.getValue()) {
      case PlanType.PayInFull: {
        return this.userSettingsService.isPifOrtho15kAssured();
      }
      case PlanType.NonGuaranteed: {
        return this.userSettingsService.isConnectOrtho15kAssured();
      }
      default: {
        return this.userSettingsService.isPlusOrtho15kAssured();
      }
    }
  }
}
